import React from "react";
import styled from "@emotion/styled";
import mediaqueries from '../styles/media';

import Section from "../components/Section";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Paginator from "../components/Navigation/Navigation.Paginator";

import ArticlesHero from "../sections/articles/Articles.Hero";
import ArticlesList from "../sections/articles/Articles.List";
import Headings from "../components/Headings";
import Paragraph from "../components/Paragraph";



function NotesPage({ location, pageContext }) {
  const articles = pageContext.group;
  const authors = pageContext.additionalContext.authors;

  const { tags } = pageContext;

  return (
    <Layout>
      <SEO
        title="Book Notes and Summaries - Nucks"
        description="This is a collection of notes and learnings from the latest books that I've read. I write down the most important points from each book so you can gain immediate value."
        pathname={location.pathname}
      />
      <Section>
        <SectionIntro>
          <MainHeading>Book Notes and Summaries</MainHeading>
          <Description>This is a collection of notes and learnings from the latest books that I've read. I write down important points from each book for later reference and so you can gain immediate value.</Description>
        </SectionIntro>
      </Section>
      <ArticlesHero
        showAuthor={false}
        showHeading={false}
        authors={authors}
      />
      <Section narrow>
        <ArticlesList articles={articles} tags={tags} />
        <NotesPaginator show={pageContext.pageCount > 1}>
          <Paginator {...pageContext} />
        </NotesPaginator>
      </Section>
    </Layout>
  );
}

export default NotesPage;

const SectionIntro = styled.div`
  margin-top: 100px;

  ${mediaqueries.phablet`
    margin-bottom: 100px;
  `};
`;

const MainHeading = styled(Headings.h1)`
  font-size: 34px;
  margin-bottom: 20px;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Helvetica Neue, Helvetica, Ubuntu, Roboto, Noto, Segoe UI, Arial, sans-serif;

  ${mediaqueries.phablet`
    font-size: 30px;
  `};
`;

const Description = styled(Paragraph)`
  margin: 0px 0px 4vw 0px;
  font-size: 20px;


  ${mediaqueries.phablet`
    padding: 0px;
    margin-bottom: 100px;
  `};
`;

const NotesPaginator = styled.div<{ show: boolean }>`
  ${p => p.show && `margin-top: 95px;`}
`;
